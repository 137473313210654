//import i18next from "i18next";
import { REASON_DESCRIPTION_MAX_LENGTH } from "./user";

export const PROFILE_DEFAULT = "Can't get user data. Please try again later";

export const BLOCK_USER_DEFAULT = "Can't block user. Please try again later.";

export const ABUSE_USER_ERROR_DEFAULT = "Can't sent report about user"; //i18next.t("abuseErrors.default");
export const ABUSE_USER_EMPTY_REASON = "Please select report reason."; //i18next.t("abuseErrors.emptyReason");
export const ABUSE_USER_EMPTY_DESCRIPTION = "Please set a description."; //i18next.t("abuseErrors.emptyDescription");
export const ABUSE_USER_TOO_LONG_DESCRIPTION = `Description is to long. Maximum symbol is ${REASON_DESCRIPTION_MAX_LENGTH}`; //i18next.t("abuseErrors.tooLong", { value: REASON_DESCRIPTION_MAX_LENGTH });
export const DESPUTE_EMPTY_DESCRIPTION = "Please set a description.";
export const DESPUTE_TOO_LONG_DESCRIPTION = "Text is too long.";

export const MAIL_DIALOGS_DEFAULT = "Can't upload dialogs.";

export const NOT_ENOUGH_CREDITS_ERROR = "Not enough credits.";
